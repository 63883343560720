import React from "react";
import Header from "../Header/Header";
import "./Hero.css";
import hero_image from "../../assets/hero_image.png";
import {motion} from 'framer-motion';
import NumberCounter from "number-counter"
// import hero_image_back from "../../assets/hero_image_back.png";
// import Heart from "../../assets/heart.png";
// import Calories from "../../assets/calories.png";

const Hero = () => {

  const transition = {type: 'spring', duration: 3}
  const mobile = window.innerWidth<=768 ? true: false;
  return (
    <div className="hero" id="home">
    <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        <div className="the-best-ad">
          <motion.div 
          initial={{left: mobile? "167px": '238px'}}
          whileInView={{left: '8px'}}
          transition={{...transition, type: 'tween'}}
          ></motion.div>
          <span>The best Lebanese Doctors in the town</span>
        </div>
        <div className="hero-text">
          <div>
            <span className="stroke-text">Get </span>
            <span>your </span>
          </div>
          <div>
            <span>perfect health</span>
          </div>
          <div>
            <span>
              Here we will help you shape and build your ideal body and live
              your life to the fullest
            </span>
          </div>
        </div>
        <div className="figures">
          <div>
            <span>
            <NumberCounter end={60} start={10} delay='1' preFix="+" />
            </span>
            <span>expert doctors</span>
          </div>
          <div>
            <span>
            <NumberCounter end={120} start={10} delay='1' preFix="+" />
            </span>
            <span>members joined</span>
          </div>
          <div>
            <span>
            <NumberCounter end={50} start={10} delay='1' preFix="+" />
            </span>
            <span>health programs</span>
          </div>
        </div>
        <div className="hero-buttons">
          <buttons className="btn">Get started</buttons>
          <buttons className="btn">Learn more</buttons>
        </div>
      </div>
      <div>
  {/*<button className="btn">Join Now</button>
        <div className="heart-rate">
          <img src={Heart} alt="heart" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </div>*/}
        <img src={hero_image} alt="hero_image" className="hero_image" />
        {/*<img
          src={hero_image_back}
          alt="hero image_back"
          className="hero_image_back"
        />

        <div className="calories">
          <img src={Calories} alt="calories" />
          <div>
            <span>Calories Burned</span>
            <span>220 kcal</span>
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default Hero;
